import React from 'react';
const Home = () => {
  return (
    <React.Fragment>
      <div id='container' className='bg-siteSky flex justify-center items-center'>
        <div className='px-8 text-center'>
          <h1 className='uppercase text-[6vw] font-medium leading-none text-siteOrange'>Dashboard</h1>
        </div>
      </div>
    </React.Fragment>
  )
}
export default Home;