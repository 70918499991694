// FetchSaveBillForm.js
import React, { useRef, useState } from "react";
import AxiosInstance from "../../util/AxiosInstance";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../notifications";

const FetchSaveBillForm = ({ onSaveBill, closeModal }) => {
  const [formData, setFormData] = useState({
    cuid: "",
    billing_unit: "",
    bill_amount: "",
    bill_date: "",
    amount_received: "",
    amount_received_rtgs: "",
    due_amount: "",
    due_date: "",
    consumer_name: "",
    unit_consumption: "",
    phone: "",
    extra_charges_cash: "",
    extra_charges_online: "",
    payment_note: "",
  });
  const errorRef = useRef(null);
  const handleInputChange = (e) => {
    if (formData.cuid.length + 1 === 12) {
      errorRef.current.style.borderColor = "green";
    } else {
      errorRef.current.style.borderColor = "red";
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleConsumerIdBlur = async () => {
    // Fetch bill details based on consumerId onBlur
    console.log("Data being sent:", { consumerId: formData.cuid });
    if (formData.cuid.length === 12) {
      try {
        const response = await AxiosInstance.post(
          "/services/MsdlServiceCustomers.php",
          {
            consumerId: formData.cuid,
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        // Set form fields with fetched data
        setFormData((prevState) => ({
          ...prevState,
          cuid: response.data.consumer_no,
          billing_unit: response.data.billing_unit,
          bill_amount: response.data.bill_amount,
          bill_date: response.data.bill_date,
          due_amount: response.data.due_amount,
          due_date: response.data.due_date,
          consumer_name: response.data.consumer_name,
          unit_consumption: response.data.unit_consumption,
          phone: response.data.phone,
          amount_received: "",
          amount_received_rtgs: "",
          extra_charges_cash: "",
          extra_charges_online: "",
          payment_note: "",
        }));
      } catch (error) {
        console.error("Error fetching bill details:", error);
      }
    } else {
      showErrorNotification('Consumer ID must greater than 12 characters.');
    }
  };

  // Save fetched data as new bill
  const { cuid } = formData;

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Send the form data to insertBill.php for saving
    if (cuid.length !== 12) {
      showErrorNotification("Invalid CUID!");
    } else {
      onSaveBill(formData);
      errorRef.current.focus();
      // Clear all the form field.
      setFormData({
        cuid: "",
        billing_unit: "",
        bill_amount: "",
        bill_date: "",
        amount_received: "",
        amount_received_rtgs: "",
        due_amount: "",
        due_date: "",
        consumer_name: "",
        unit_consumption: "",
        phone: "",
        extra_charges_cash: "",
        extra_charges_online: "",
      });
    }
  };
  window.addEventListener("keydown", (e) => {
    if (e.key === "Escape") closeModal();
  });

  const inputStyle = `border w-full h-[35px] outline-none px-2`;
  return (
    <div className="relative">
      <button
        className="w-[40px] h-[40px] text-white bg-siteBlue absolute flex justify-center block items-center -top-5 -right-5 rounded-full z-40"
        onClick={closeModal}
      >
        <i className="fa-solid fa-xmark text-lg"></i>
      </button>

      <form
        id="saveBill"
        onSubmit={handleFormSubmit}
        className="relative flex flex-col bg-white p-8 rounded-xl gap-2 w-[600px]"
      >
        <h2 className="text-2xl font-semibold text-[#FF612D]">Add New Bill</h2>
        <div className="flex gap-4">
          <div className="w-full">
            <label htmlFor="cuIdInput" className="text-sm">
              Consumer ID:
            </label>
            <input
              type="text"
              name="cuid"
              id="cuIdInput"
              className={inputStyle}
              value={formData.cuid}
              onChange={handleInputChange}
              onBlur={handleConsumerIdBlur}
              ref={errorRef}
            />
          </div>
          <div className="w-full">
            <label htmlFor="billingUnitInput" className="text-sm">
              Billing Unit:
            </label>
            <input
              type="text"
              name="billing_unit"
              id="billingUnitInput"
              className={inputStyle}
              value={formData.billing_unit}
              readOnly
            />
          </div>
        </div>

        <div className="flex gap-4">
          <div className="w-full">
            <label htmlFor="billAmountInput" className="text-sm">
              Bill Amount:
            </label>
            <input
              type="text"
              name="bill_amount"
              id="billAmountInput"
              className={inputStyle}
              value={formData.due_amount}
              readOnly
            />
          </div>
          <div className="w-full">
            <label htmlFor="billDateInput" className="text-sm">
              Bill Date:
            </label>
            <input
              type="text"
              name="bill_date"
              id="billDateInput"
              className={inputStyle}
              value={formData.bill_date}
              readOnly
            />
          </div>
        </div>

        <fieldset className="border p-3 flex gap-4">
          <legend className="text-sm">Amount Received</legend>
          <div className="flex-gap-4">
            <label htmlFor="amountReceivedInput" className="text-sm">
              Cash
            </label>
            <input
              type="text"
              name="amount_received"
              id="amountReceivedInput"
              className={inputStyle}
              // defaultValue={formData.bill_amount}
              defaultValue={formData.due_amount}
              // value={formData.bill_amount}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex-gap-4">
            <label htmlFor="amountReceivedInput" className="text-sm">
              Online/RTGS
            </label>
            <input
              type="text"
              name="amount_received_rtgs"
              id="amountReceivedInputRtgs"
              className={inputStyle}
              value={formData.amount_received_rtgs}
              onChange={handleInputChange}
            />
          </div>
        </fieldset>

        <fieldset className="border px-3 py-2 flex gap-4">
          <legend className="text-sm">Extra Charges:</legend>
          <div className="w-full">
            <label htmlFor="extraChargesInput" className="text-sm">
              Cash
            </label>
            <input
              type="text"
              name="extra_charges_cash"
              id="extraChargesInput"
              className={inputStyle}
              value={formData.extra_charges_cash}
              onChange={handleInputChange}
            />
          </div>
          <div className="w-full">
            <label htmlFor="extraChargesInput" className="text-sm">
              Online/RTGS
            </label>
            <input
              type="text"
              name="extra_charges_online"
              id="extraChargesInput"
              className={inputStyle}
              value={formData.extra_charges_online}
              onChange={handleInputChange}
            />
          </div>
        </fieldset>

        <div className="w-full">
          <label htmlFor="extraChargesInput" className="text-sm">
            Notes
          </label>
          <input
            type="text"
            name="payment_note"
            id="notesInput"
            className={inputStyle}
            value={formData.payment_note}
            onChange={handleInputChange}
          />
        </div>

        {/* Hidden fields */}
        <input
          type="hidden"
          name="consumer_name"
          id="consumerNameInput"
          value={formData.consumer_name}
        />
        <input
          type="hidden"
          name="unit_consumption"
          id="consumptionUnitInput"
          value={formData.unit_consumption}
        />
        <input
          type="hidden"
          name="phone"
          id="PhoneInput"
          value={formData.phone}
        />
        <input
          type="hidden"
          name="due_date"
          id="dueDateInput"
          value={formData.due_date}
        />
        <input
          type="hidden"
          name="due_amount"
          id="dueAmountInput"
          value={formData.due_amount}
        />
        <button
          type="submit"
          className="bg-siteOrange text-white h-[35px] mt-3 border border-transparent"
        >
          Save
        </button>
      </form>
    </div>
  );
};

export default FetchSaveBillForm;
