import React from "react";
const PortalFooter = () => {
  return (
    <React.Fragment>
      <footer className=" bg-siteBlue h-[35px] flex justify-center items-center sticky w-full bottom-0 text-center border-t">
        <p className="text-gray-300 text-[14px]"> &copy; 2024 <span className="font-medium text-white">Sona MSDL.</span> All rights reserved.</p>
      </footer>
    </React.Fragment>
  );
}
export default PortalFooter;