// FetchSaveBillForm.js
import React, { useState, useEffect } from "react";

const UpdateBillStatus = ({ onBillUpdate, closeModal, dataToUpdate }) => {
console.log(dataToUpdate);
  const [formData, setFormData] = useState({
    id: dataToUpdate.id,
    cid: dataToUpdate.cid,
    billing_unit: dataToUpdate.billing_unit,
    bill_amount: dataToUpdate.bill_amount,
    bill_date: dataToUpdate.bill_date,
    amount_received_cash: dataToUpdate.amount_received,
    amount_received_rtgs: dataToUpdate.amount_received_rtgs,
    charges_cash: dataToUpdate.charges_cash,
    charges_online: dataToUpdate.charges_online,
    total_amount_received: dataToUpdate.total_amount_received,
    due_date: dataToUpdate.due_date,
    due_amount: dataToUpdate.due_amount,
    phone: dataToUpdate.phone,
    consumer_name: dataToUpdate.consumer_name,
    unit_consumed: dataToUpdate.unit_consumed,
    payment_status: dataToUpdate.payment_status,
    payment_method: dataToUpdate.payment_method,
    created_on: dataToUpdate.created_on,
    payment_note: dataToUpdate.payment_note,
    isNew: false,
  });

  const handleInputChange = (e) => {
    let newValue;
    if (e.target.type === 'checkbox') {
      newValue = e.target.checked ? 'Paid' : 'Pending';
    } else {
      newValue = e.target.value;
    }
    setFormData(prevState => ({
      ...prevState,
      [e.target.name]: newValue
    }), );
  };

  useEffect(() => {
    console.log(formData); // Log the updated state whenever it changes
  }, [formData]); // Run this effect whenever formData changes

  // Save fetched data as new bill
  const handleFormSubmit = (e) => {
    e.preventDefault();
    onBillUpdate({ ...formData, isNew: !formData.isNew });
    closeModal();
  };

  window.addEventListener("keydown", (e) => {
    if (e.key === "Escape") closeModal();
  });

  const inputStyle = `border w-full mb-[4px] h-[30px] outline-none px-2`;
  return (
    <div className="relative">
      <button
        className="w-[40px] h-[40px] text-white bg-siteBlue absolute flex justify-center block items-center -top-5 -right-5 rounded-full z-40"
        onClick={closeModal}
      >
        <i className="fa-solid fa-xmark text-lg"></i>
      </button>
      <form
        id="saveBill"
        onSubmit={handleFormSubmit}
        className="relative flex flex-col bg-white p-8 rounded-xl gap-2 w-[600px]"
      >
        <h2 className="text-2xl font-semibold text-[#FF612D]">Update Bill</h2>
        <div className="flex gap-4">
          <div className="w-full">
            <label htmlFor="cuIdInput" className="text-sm">
              Consumer ID:
            </label>
            <input
              type="text"
              name="cuid"
              id="cuIdInput"
              className={inputStyle}
              value={formData.cid}
              onChange={handleInputChange}
              readOnly
            />
          </div>
          {/* <div className="w-full">
            <label htmlFor="billingUnitInput" className="text-sm">
              Billing Unit:
            </label>
            <input
              type="text"
              name="billing_unit"
              id="billingUnitInput"
              className={inputStyle}
              value={formData.billing_unit}
              readOnly
            />
          </div> */}
        </div>

        <div className="flex gap-4">
          <div className="w-full">
            <label htmlFor="billAmountInput" className="text-sm">
              Bill Amount:
            </label>
            <input
              type="text"
              name="bill_amount"
              id="billAmountInput"
              className={inputStyle}
              value={formData.bill_amount}
              onChange={handleInputChange}
            />
          </div>
          <div className="w-full">
            <label htmlFor="billDateInput" className="text-sm">
              Bill Date:
            </label>
            <input
              type="text"
              name="bill_date"
              id="billDateInput"
              className={inputStyle}
              value={formData.bill_date}

            />
          </div>
        </div>

        <fieldset className="border p-3 flex gap-4">
          <legend className="font-semibold text-sm">Amount Received</legend>
          <div className="flex-gap-4">
            <label htmlFor="amountReceivedInput" className="text-sm">
              Cash
            </label>
            <input
              type="text"
              name="amount_received_cash"
              id="amountReceivedInput"
              className={inputStyle}
              value={formData.amount_received_cash}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex-gap-4">
            <label htmlFor="amountReceivedInput" className="text-sm">
              Online
            </label>
            <input
              type="text"
              name="amount_received_rtgs"
              id="amountReceivedInput"
              className={inputStyle}
              value={formData.amount_received_rtgs}
              onChange={handleInputChange}
            />
          </div>
        </fieldset>

        <fieldset className="border px-3 py-2 flex gap-4">
          <legend className="font-semibold text-sm">Extra Charges</legend>
          <div className="w-full">
            <label htmlFor="extraChargesInput" className="text-sm">
              Cash
            </label>
            <input
              type="text"
              name="charges_cash"
              id="extraChargesInput"
              className={inputStyle}
              value={formData.charges_cash}
              onChange={handleInputChange}
            />
          </div>
          <div className="w-full">
            <label htmlFor="extraChargesInput" className="text-sm">
              Online/RTGS
            </label>
            <input
              type="text"
              name="charges_online"
              id="extraChargesInput"
              className={inputStyle}
              value={formData.charges_online}
              onChange={handleInputChange}
            />
          </div>
        </fieldset>

        <div className="flex items-center gap-2">
          <label htmlFor="billStatus" className="text-sm">
            Bill Status:
          </label>
          <input type="checkBox" name="payment_status" onChange={handleInputChange} checked={formData.payment_status === 'Paid'}></input>
        </div>
        <button
          type="submit"
          className="bg-siteOrange text-white h-[35px] mt-3 border border-transparent"
        >
          Update
        </button>
      </form>
    </div>
  );
};

export default UpdateBillStatus;
