import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
const PortalNavbar = () => {
  const navigate = useNavigate();
  const logout = () => {
    localStorage.clear();
    navigate('/auth/login');
  }

  const linkStyle = `text-white/50 text-[15px] font-normal`
  return (
    <React.Fragment>
      <header className="sticky top-0 z-10  bg-siteBlue flex justify-between items-center min-h-[60px]  px-8 ">
        <h2 className="text-[24px] text-white font-bold">SABA Enterprises</h2>
        <ul className="flex gap-[30px] items-center">
          <NavLink to="/" className={linkStyle}>Home</NavLink>
          <NavLink to="/customers" className={linkStyle}>Customers</NavLink>
          <NavLink to="/accounts" className={linkStyle}>Accounts</NavLink>
          <button onClick={logout} className="bg-siteOrange font-medium text-[15px] text-white px-6 h-[35px]">Logout</button>
        </ul>
      </header>
    </React.Fragment>
  );
}
export default PortalNavbar;