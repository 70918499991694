import React, { useEffect, useRef, useState } from "react";
import PortalNavbar from "../navbar/PortalNavbar";
import PortalFooter from "../footer/PortalFooter";
import AxiosInstance from "../../util/AxiosInstance";
import { UtilityFormatNum } from "../../util/Utility";
import {
  showSuccessNotification,
  showErrorNotification,
} from "../notifications";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Accounts = () => {
  const [transactions, setTransactions] = useState([]);
  const [fetchedPreview, setFetchedPreview] = useState([]);
  const [showPreview, setShowPreview] = useState(true);
  const [formData, setFormData] = useState({
    from: "",
    to: "",
  });

  // Define ref for FromId.
  const inputRef = useRef(null);

  // Update formData on input change.
  const handleOnChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Fetch new account details.
  const handleFetchDetails = (e) => {
    e.preventDefault();
    if (!formData.from) {
      // Show error message or handle empty fields
      showErrorNotification("FROM field is required.");
      return;
    } else if (!formData.to) {
      showErrorNotification("TO field is required.");
      return;
    } else {
      setShowPreview(true);
      fetchData(formData.from, formData.to, false);
    }
  };

  // Save account details.
  const handleSaveDetails = async () => {
    try {
      const response = await AxiosInstance.post(
        "/services/SaveAccounts.php",
        fetchedPreview,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      console.log(response);
      if (response.data.error) {
        showErrorNotification(response.data.error);
      } else {
        showSuccessNotification(response.data.data);
      }
      setShowPreview(false);
      // After saving, fetch the data
      fetchData(null, null, true);
      // Reset the form fields.
      setFormData({ from: "", to: "" });
    } catch (error) {
      console.log("Error saving ", error);
    }
  };

  // DeleteHandler of single account
  const deleteHandler = async (e) => {
    e.preventDefault();
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this record?"
    );
    if (isConfirmed) {
      const postData = {
        id: e.target.dataset.value,
        action: "delete",
      };

      try {
        const response = await AxiosInstance.post(
          "/services/SaveAccounts.php",
          postData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        if (response.status === 200) {
          showErrorNotification(response.data);
          fetchData(null, null, true);
        }
        // Keep cursor
        inputRef.current.focus();
      } catch (error) {
        console.log("Error", error);
      }
    }
  };

  // Fetch Data on first load.
  const fetchData = async (fromId = null, toId = null, isFirstLoad = true) => {
    const params = { from_id: fromId, to_id: toId };
    try {
      const response = await AxiosInstance.post(
        "/services/FetchAccounts.php",
        params,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (isFirstLoad) {
        setTransactions(response.data);
      } else {
        // setFetchedPreview(prevState => [...prevState, ...response.data]);
        console.log(response.data);
        setFetchedPreview(response.data);
      }
      // Keep cursor
      inputRef.current.focus();
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // styles
  const inputStyle = `w-[160px] h-[30px] outline-none px-2 text-sm focus:shadow-md ease-in duration-200`;
  const labelStyle = `text-[15px] text-siteBlue font-medium`;
  const thStyle = `text-sm font-bold px-[3px] border border-[#aaa] text-siteBlue text-left`;
  const tdStyle = `text-sm font-medium px-[3px] border`;

  const accountPreviewList =
    "w-full px-4 py-2 border-b border-gray-200 flex justify-between items-center";
  return (
    <React.Fragment>
      <PortalNavbar />
      <div id="container" className="bg-siteSky px-2 relative">
        {/* <h3 className="text-[15px] bg-siteOrange uppercase rounded-b-lg font-semibold text-white px-4 text-center py-1 absolute left-[50%] -translate-x-[50%] ">
          Daily Accounts
        </h3> */}
        <div className="flex gap-4 items-start flex-col mb-4 pt-4">
          {/* <h4 className="bg-siteOrange w-[240px] rounded-r-full py-1 text-white absolute left-0 pl-8">Fetch and Save Details</h4> */}
          <form
            id="accountForm"
            onSubmit={handleFetchDetails}
            className="flex gap-4 items-end"
          >
            <div className="flex flex-col">
              {/* <label className={labelStyle}>From ID:</label> */}
              <input
                type="text"
                name="from"
                value={formData.from}
                onChange={handleOnChange}
                className={inputStyle}
                placeholder="From ID:"
                ref={inputRef}
              />
            </div>
            <div className="flex flex-col">
              {/* <label className={labelStyle}>To ID:</label> */}
              <input
                type="text"
                name="to"
                value={formData.to}
                onChange={handleOnChange}
                className={inputStyle}
                placeholder="To ID:"
              />
            </div>
            <input
              type="submit"
              value="Fetch Details"
              className="h-[30px] bg-siteBlue text-sm font-medium w-[130px] text-white cursor-pointer"
            />
          </form>
          {showPreview &&
            fetchedPreview.map((transaction) => (
              <div className="fixed top-0 left-0 bg-black/70 h-screen z-50 w-full flex justify-center items-center">
                <div className="bg-white w-[300px] p-4 flex flex-col gap-2 rounded-lg relative">
                  <button
                    className="w-[40px] h-[40px] text-white bg-siteBlue absolute -top-5 -right-5 rounded-full"
                    onClick={() => setShowPreview(!showPreview)}
                  >
                    X
                  </button>
                  <ul className="text-sm font-medium text-gray-900 bg-white border border-gray-200">
                    <li className={accountPreviewList}>
                      Bill Amount:{" "}
                      <span className="text-right">
                        {UtilityFormatNum(parseInt(transaction.bill_amounts))}
                      </span>
                    </li>
                    <li className={accountPreviewList}>
                      Received (Cash):{" "}
                      <span className="text-right">
                        {UtilityFormatNum(
                          parseInt(transaction.received_cash)
                        )}
                      </span>
                    </li>
                    <li className={accountPreviewList}>
                      Received (RTGS):{" "}
                      <span className="text-right">
                        {UtilityFormatNum(
                          parseInt(transaction.received_rtgs)
                        )}
                      </span>
                    </li>
                    <li className={accountPreviewList}>
                      {`${"Charges (Cash)"}`}:{" "}
                      <span className="text-right">
                        {UtilityFormatNum(parseInt(transaction.charges_cash))}
                      </span>
                    </li>
                    <li className={accountPreviewList}>
                      {`${"Charges (Online)"}`}:{" "}
                      <span className="text-right">
                        {UtilityFormatNum(parseInt(transaction.charges_online))}
                      </span>
                    </li>
                  </ul>
                  <button
                    type="button"
                    onClick={handleSaveDetails}
                    className="bg-siteOrange text-white h-[35px] mt-3 border border-transparent"
                  >
                    Save
                  </button>
                </div>
              </div>
            ))}
        </div>

        <table className="w-full bg-white border ">
          <thead>
            <tr className="bg-[#ccc]">
              <th className={thStyle}>From</th>
              <th className={thStyle}>To</th>
              <th className={thStyle}>Bill Amounts</th>
              <th className={thStyle}>Recd. Cash</th>
              <th className={thStyle}>Recd. RTGS</th>
              <th className={thStyle}>Charges Cash</th>
              <th className={thStyle}>Charges Online</th>
              <th className={thStyle}>Date</th>
              <th className={thStyle}>Action</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction) => (
              <tr
                key={transaction.id}
                className="hover:bg-[#eee] border border-gray-200"
              >
                <td className={tdStyle}>{transaction.from_id}</td>
                <td className={tdStyle}>{transaction.to_id}</td>
                <td className={tdStyle}>
                  {UtilityFormatNum(parseInt(transaction.bill_amounts))}
                </td>
                <td className={tdStyle}>
                  {UtilityFormatNum(parseInt(transaction.received_cash))}
                </td>
                <td className={tdStyle}>
                  {UtilityFormatNum(parseInt(transaction.received_rtgs))}
                </td>
                <td className={tdStyle}>
                  {UtilityFormatNum(parseInt(transaction.charges_cash))}
                </td>
                <td className={tdStyle}>
                  {UtilityFormatNum(parseInt(transaction.charges_online))}
                </td>
                <td className={tdStyle}>{transaction.created_on}</td>
                <td className={`${tdStyle} text-center`}>
                  <button
                    className="font-medium text-red-600  hover:underline"
                    onClick={deleteHandler}
                  >
                    <i className="fa-solid fa-trash" data-value={transaction.id}></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ToastContainer />
      </div>
      <PortalFooter />
    </React.Fragment>
  );
};

export default Accounts;
