import React from "react";
import { useNavigate } from "react-router-dom";
import AxiosInstance from "../../util/AxiosInstance";
import elecBill from "../../assets/electric-bill.jpg"
const Login = () => {
  const navigate = useNavigate();

  const submitLoginForm = async (event) => {
    event.preventDefault();
    const formElement = document.querySelector('#loginForm');
    const formData = new FormData(formElement);
    const formDataJSON = Object.fromEntries(formData);
    const btnPointer = document.querySelector('#login-btn');
    btnPointer.innerHTML = 'Please wait..';
    btnPointer.setAttribute('disabled', true);
    // Headers to be added to the request
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    try {
      const response = await AxiosInstance.post('/includes/axiosLogin.php', formDataJSON, { headers });
      btnPointer.innerHTML = 'Login';
      btnPointer.removeAttribute('disabled');
      const data = response.data;
      const token = data.token;
      if (!token) {
        alert('Unable to login. Please try after some time.');
        return;
      }
      localStorage.clear();
      localStorage.setItem('user-token', token);
      setTimeout(() => {
        navigate('/');
      }, 500);

    } catch (error) {
      btnPointer.innerHTML = 'Login';
      btnPointer.removeAttribute('disabled');
      alert("Oops! Some error occured.");
      console.log("Login Error: ", error);
    }

  }


  const inputControl = `bg-white h-[45px] w-full outline-none px-3 text-md rounded-md focus:shadow-lg focus:shadow-siteBlue/10 ease-in duration-200 font-medium`;

  return (
    <div className="h-screen flex">
      <div className="w-full md:w-[40%] h-screen bg-siteSky flex flex-col justify-center items-center">
        <div className="mb-[5rem] text-center">
          <h3 className="text-[30px] font-bold mb-2 leading-none text-siteOrange  ">
          SABA Enterprises
          </h3>
          <h2 className="text-[45px] font-bold leading-none text-siteBlue">
            Welcome Back!
          </h2>
        </div>
        <form onSubmit={submitLoginForm} className="flex flex-col gap-6 w-[380px]" id="loginForm" >
          <div className="flex flex-col">
            <label htmlFor={'login-username'} className="font-medium mb-1 text-siteBlue">
              Username
            </label>
            <input type="text" className={inputControl} id={'login-username'} name="username" required />
          </div>
          <div className="flex flex-col">
            <label htmlFor={'login-password'} className="font-medium mb-1 text-siteBlue">
              Password
            </label>
            <input type="password" className={inputControl} id={'login-password'} name="password" required />
          </div>
          <button type="submit" className="w-full bg-siteOrange text-white h-[45px] mt-5 rounded-md font-medium" id="login-btn">
            Login
          </button>
        </form>
      </div>
      <div className="hidden w-[60%] h-screen lg:flex justify-center items-center">
        <img src={elecBill} alt="Electric Bill" className="w-[55%]" />
      </div>
    </div>
  );
};

export default Login;
