import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import PortalNavbar from "../navbar/PortalNavbar";
import PortalFooter from "../footer/PortalFooter";
import AxiosInstance from "../../util/AxiosInstance";
import FetchSaveBillForm from "./FetchSaveBillForm";
import UpdateBillStatus from "./UpdateBillStatus";
import { ToastContainer } from "react-toastify";
import {
  showSuccessNotification,
  showErrorNotification,
} from "../notifications";

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [activePage, setActivePage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [isModalFetch, setIsModalFetch] = useState(false);
  const [isModalUpdate, setIsModalUpdate] = useState(false);
  const [dataToUpdate, setDataToUpdate] = useState({});
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  const [filter, setFilter] = useState({
    consumerId: "",
    minAmount: "",
    maxAmount: "",
    selectedMonth: (new Date().getMonth() + 1).toString().padStart(2, "0"),
    selectedYear: new Date().getFullYear(),
  });

  const itemsPerPage = 25;
  // styles
  const inputStyle = `w-[160px] h-[30px] outline-none px-2 text-sm focus:shadow-md ease-in duration-200`;
  // const labelStyle = `text-[15px] text-siteBlue font-medium`
  const thStyle = `text-sm font-bold px-[3px] border border-[#aaa] text-siteBlue`;
  const tdStyle = `text-sm font-medium px-[3px] border`;

  const handleInputChange = (e) => {
    setFilter({
      ...filter,
      [e.target.id]: e.target.value,
    });
  };

  // Handle change year and month.
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    setFilter({ ...filter, [event.target.id]: event.target.value });
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    setFilter({ ...filter, [event.target.id]: event.target.value });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setActivePage(0);
    fetchData(0);
  };

  const handleReset = (e) => {
    e.preventDefault();
    const currentYear = new Date().getFullYear();
    const currentMonth = (new Date().getMonth() + 1)
      .toString()
      .padStart(2, "0");
    setSelectedYear(currentYear.toString());
    setSelectedMonth(currentMonth);
    setFilter({
      consumerId: "",
      minAmount: "",
      maxAmount: "",
      selectedMonth: currentMonth,
      selectedYear: currentYear,
    });
    // fetchData(0);
  };

  // Handle bill save.
  const handleSaveBill = async (newBillData) => {
    console.log("New bill data: ", newBillData);
    try {
      const saveResponse = await AxiosInstance.post(
        "/services/BillSave.php",
        newBillData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      showSuccessNotification(saveResponse.data);
      fetchData(0);
    } catch (error) {
      showErrorNotification(error.message);
      console.error("Error saving bill:", error);
    }
  };

  // Handle update bill status.
  const handleUpdateBill = async (newBillData) => {
    try {
      const saveResponse = await AxiosInstance.post(
        "/services/UpdateBillStatus.php",
        newBillData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      showSuccessNotification("Bill saved successfully:");
      console.log("Bill saved successfully:", saveResponse.data);
      fetchData(0);
    } catch (error) {
      showErrorNotification(error);
      console.error("Error saving bill:", error);
    }
  };

  const openModalFetch = () => {
    setIsModalFetch(true);
  };

  const closeModalFetch = () => {
    setIsModalFetch(false);
  };

  const opanModalUpdate = (id) => {
    console.log(customers);
    const updatingData = customers.filter((item) => {
      return item.id === id;
    });
    console.log(updatingData);
    setDataToUpdate(updatingData[0]);
    setIsModalUpdate(true);
  };

  const closeModalUpdate = () => {
    setIsModalUpdate(false);
  };

  const handlePageChange = (selectedPage) => {
    setActivePage(selectedPage.selected);
    fetchData(selectedPage.selected);
  };

  // fetchData function.
  const fetchData = async (page = 0) => {
    try {
      const response = await AxiosInstance.get(`/services/FetchCustomers.php`, {
        params: {
          page: page + 1,
          consumerId: filter.consumerId,
          minAmount: filter.minAmount,
          maxAmount: filter.maxAmount,
          selectedMonth: filter.selectedMonth,
          selectedYear: filter.selectedYear,
        },
      });

      setCustomers(response.data.data);
      const calculatedTotalPages = Math.ceil(
        response.data.totalRecords / itemsPerPage
      );
      setTotalPages(calculatedTotalPages);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  // Fetch the data on first load.
  useEffect(() => {
    // set Current year.
    const currentYear = new Date().getFullYear();
    setSelectedYear(currentYear.toString());
    // Set the default selectedMonth to the current month
    const currentMonth = (new Date().getMonth() + 1)
      .toString()
      .padStart(2, "0");
    setSelectedMonth(currentMonth);
    fetchData(0);
  }, []);

  // Create an array of month names
  const monthNames = [
    { value: "", label: "-- All --" },
    { value: "01", label: "Jan" },
    { value: "02", label: "Feb" },
    { value: "03", label: "Mar" },
    { value: "04", label: "Apr" },
    { value: "05", label: "May" },
    { value: "06", label: "Jun" },
    { value: "07", label: "Jul" },
    { value: "08", label: "Aug" },
    { value: "09", label: "Sep" },
    { value: "10", label: "Oct" },
    { value: "11", label: "Nov" },
    { value: "12", label: "Dec" },
  ];

  // Create Years array.
  const startYear = 2023;
  const endYear = 2030;
  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, index) => startYear + index
  );

  return (
    <React.Fragment>
      <PortalNavbar />
      <div id="container" className="bg-siteSky ">
        {/* <h3 className="text-[15px] bg-siteOrange uppercase rounded-b-lg font-semibold text-white px-4 text-center py-1 absolute left-[50%] -translate-x-[50%] ">
          All Customer
        </h3> */}

        {isModalFetch && (
          <div className="fixed top-0 bg-black/70 h-screen z-50 w-full flex justify-center items-center">
            <FetchSaveBillForm
              onSaveBill={handleSaveBill}
              closeModal={closeModalFetch}
            />
          </div>
        )}
        {isModalUpdate && (
          <div className="fixed top-0 bg-black/70 h-screen z-50 w-full flex justify-center items-center">
            <UpdateBillStatus
              onBillUpdate={handleUpdateBill}
              closeModal={closeModalUpdate}
              dataToUpdate={dataToUpdate}
              handleConsumerIdBlur
            />
          </div>
        )}

        {/* Render Filter Form */}
        <div className="px-2 pt-4 mb-4 flex justify-between items-end">
          <form onSubmit={handleSearch}>
            <div className="flex gap-[18px] items-end">
              <div className="flex flex-col">
                <input
                  type="text"
                  className={inputStyle}
                  id="consumerId"
                  placeholder="Enter Consumer ID"
                  value={filter.consumerId}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex flex-col">
                <input
                  type="text"
                  className={inputStyle}
                  id="minAmount"
                  placeholder="Min Bill Amount"
                  value={filter.minAmount}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex flex-col">
                <input
                  type="text"
                  className={inputStyle}
                  id="maxAmount"
                  placeholder="Max Bill Amount"
                  value={filter.maxAmount}
                  onChange={handleInputChange}
                />
              </div>

              <div className="flex flex-col">
                <select
                  id="selectedMonth"
                  className={inputStyle}
                  onChange={handleMonthChange}
                  value={selectedMonth}
                >
                  {monthNames.map((month) => (
                    <option key={month.value} value={month.value}>
                      {month.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex flex-col">
                <select
                  id="selectedYear"
                  className={inputStyle}
                  value={selectedYear}
                  onChange={handleYearChange}
                >
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex gap-2">
                <button
                  type="submit"
                  id="searchButton"
                  className="h-[30px] bg-siteBlue text-sm font-medium w-[100px] text-white"
                >
                  Search
                </button>
                <button
                  type="button"
                  onClick={handleReset}
                  className="h-[30px] bg-siteOrange text-sm font-medium w-[100px] text-white"
                >
                  Reset
                </button>
              </div>
            </div>
          </form>
          <button
            onClick={openModalFetch}
            className="h-[30px] bg-siteOrange text-sm font-medium w-[140px] text-white"
          >
            Add Customer
          </button>
        </div>

        {/* Render Filter Form */}
        <div className="px-2">
          <table id="billsTable" className="w-full bg-white border text-left">
            <colgroup>
              <col style={{ width: "3%" }} />
              <col style={{ width: "3%" }} />
              <col style={{ width: "2%" }} />
              <col style={{ width: "4%" }} />
              <col style={{ width: "4%" }} />
              <col style={{ width: "3%" }} />
              <col style={{ width: "3%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "4%" }} />
              <col style={{ width: "4%" }} />
              <col style={{ width: "4%" }} />
              <col style={{ width: "4%" }} />
              <col style={{ width: "3%" }} />
              <col style={{ width: "3%" }} />
            </colgroup>
            <thead>
              <tr className="bg-[#ccc]">
                <th className={thStyle}>ID</th>
                <th className={thStyle}>CUID</th>
                <th className={thStyle}>BU</th>
                <th className={thStyle}>Amt. Cash</th>
                <th className={thStyle}>Amt. RTGS</th>
                <th className={thStyle}>Ch. Cash</th>
                <th className={thStyle}>Ch. Online</th>
                <th className={thStyle}>Note</th>
                <th className={thStyle}>Total</th>
                <th className={thStyle}>Bill Dt.</th>
                <th className={thStyle}>Bill Amt.</th>
                <th className={thStyle}>Pay. Status</th>
                <th className={thStyle}>Action</th>
                <th className={thStyle}>Created on</th>
              </tr>
            </thead>
            <tbody>
              {customers.map((item) => (
                <tr
                  key={item.id}
                  className="hover:bg-[#eee] text-left border border-gray-200"
                >
                  <td className={tdStyle}>{item.id}</td>
                  <td className={tdStyle}>
                    <span title={`${item.consumer_name}`}>{item.cid}</span>
                  </td>
                  <td className={tdStyle}>{item.billing_unit}</td>

                  <td className={`${tdStyle} text-right`}>
                    {item.amount_received}
                  </td>
                  <td className={`${tdStyle} text-right`}>
                    {item.amount_received_rtgs}
                  </td>
                  <td className={`${tdStyle} text-right`}>
                    {item.charges_cash}
                  </td>
                  <td className={`${tdStyle} text-right`}>
                    {item.charges_online}
                  </td>
                  <td className={tdStyle}>{item.payment_note}</td>
                  <td className={tdStyle}>
                    {parseInt(item.amount_received) +
                      parseInt(item.amount_received_rtgs) +
                      parseInt(item.charges_cash) +
                      parseInt(item.charges_online)}
                  </td>
                  <td className={tdStyle}>{item.bill_date}</td>
                  <td className={`${tdStyle} text-right`}>
                    {item.bill_amount}
                  </td>
                  <td className={tdStyle}>{item.payment_status}</td>
                  <td className={`${tdStyle} text-center`}>
                    <button
                      className="font-medium text-black"
                      data-value={item.id}
                      onClick={() => opanModalUpdate(item.id)}
                    >
                      <i className="fa-solid fa-pen-to-square"></i>
                    </button>
                  </td>
                  <td className={tdStyle}>{item.created_on}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <ReactPaginate
            pageCount={totalPages}
            pageRangeDisplayed={5} // Number of page links to display
            marginPagesDisplayed={2} // Number of pages to display for margin pages
            onPageChange={handlePageChange}
            containerClassName="pagination"
            activeClassName="active"
            forcePage={activePage}
          />
        </div>
      </div>
      <ToastContainer />
      <PortalFooter />
    </React.Fragment>
  );
};

export default Customers;
